import logo from './logo.svg';
import './App.css';
import Chatbot from './components/ChatBot';

const apiHost = process.env.REACT_APP_API
function App() {
  return (
    <Chatbot src={apiHost}/>
  );
}

export default App;
